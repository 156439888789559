import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import BaseStatus from '@/components/UI/BaseStatus.vue';
import BaseBadge from '@/components/UI/BaseBadge.vue';
import CommentExpansion from '@/components/UI/CommentExpansion.vue';
import AddFileModal from '@/components/modals/addFileModal/AddFileModal.vue';
import { formatMoney, paginationRequest } from '@/utils/utils';
import AnalyzeItem from '@/components/items/AnalyzeItem.vue';
import DownloadFile from '@/components/DownloadFile.vue';
import { getOrderedService, getOrderedServiceResults } from '@/api/ordered-services.js';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
import InfiniteLoading from 'vue-infinite-loading';
let OrderPage = class OrderPage {
    constructor() {
        this.cardsData = [];
        this.orderedService = {};
        this.results = [];
        this.loading = false;
    }
    async mounted() {
        const ordered_service_id = this.$route.params.id;
        this.loading = true;
        const [orderedService, result] = await Promise.all([
            getOrderedService({ ordered_service_id }),
            getOrderedServiceResults({ ordered_service_id })
        ]);
        this.orderedService = orderedService;
        this.results = result;
        this.loading = false;
    }
    async loadNextPage($state) {
        const params = {
            ordered_service_id: this.$route.params.id,
            offset: this.results.length,
            count: 20
        };
        paginationRequest({ request: getOrderedServiceResults, params, targetArray: this.results, $state });
    }
    setCardsData() {
        this.cardsData = [
            {
                title: 'Дата сдачи',
                desk: this.$date(new Date(this.orderedService.date), 'dd.MM.yyyy')
            },
            {
                title: 'Стоимость',
                desk: `${formatMoney(this.orderedService?.price)} ₽`,
            },
        ];
    }
    get showResults() {
        const { status } = this.orderedService;
        return status === 5 || status === null;
    }
};
__decorate([
    Watch('orderedService', { deep: true })
], OrderPage.prototype, "setCardsData", null);
OrderPage = __decorate([
    Component({
        components: {
            AddFileModal,
            MainBtn,
            BaseStatus,
            BaseBadge,
            CommentExpansion,
            AnalyzeItem,
            DownloadFile,
            LoadingSpinner,
            InfiniteLoading
        },
    })
], OrderPage);
export default OrderPage;
